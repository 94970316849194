import React from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

class FCaptcha extends React.Component {
  render () {
    const {
      gReCPATCHAKey,
      input: {
        onChange
      },
      className
    } = this.props
    return (
      <div class={className}>
        {/* <ReCAPTCHA sitekey={gReCPATCHAKey} onChange={response => onChange(response)} /> */}
        <ReCAPTCHA sitekey={gReCPATCHAKey} onChange={onChange} />
      </div>
    )
  }
}

export default FCaptcha
