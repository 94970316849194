import React from 'react'

import { graphql } from 'gatsby'

import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../components/layout/Layout'
import ContactForm from '../components/forms/ContactForm'
import CustomerServiceCard from '../components/layout/CustomerServiceCard'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  pageContainer: {
    paddingTop: '140px',
    paddingBottom: '80px'
  },
  pageGrid: {
    flexGrow: 1
  },
  h1: {
    // fontSize: '40px',
    // fontWeight: 'bold',
    // lineHeight: '44px',
    // color: siteTheme.color3
    paddingBottom: '24px'
  },
  subtitle1: {
    // fontSize: '16px',
    // fontWeight: 500,
    // lineHeight: '28px',
    // color: siteTheme.color8
  }
})

const contact = (props) => {
  const pathname = props.location.pathname
  const {
    apiUrl,
    gReCPATCHAKey
  } = props.data.site.siteMetadata
  const classes = props.classes

  // console.log(`Using config apiUrl: ${apiUrl}`)
  // console.log(`Using config gReCAPTCHAKey: ${gReCPATCHAKey}`)

  return (
    <Layout activeSection='contact'
      title='Nous contacter'
      description='Formulaire de contact Phileass pour tout type de demande : prospects, clients, candidats, etc.'
      pathname={pathname}>
      <div className={classes.layout}>
        <div className={classes.pageContainer}>
          <Grid container
            direction='row'
            justify='space-between'
            alignItems='flex-start'
            alignContent='stretch'
            spacing={16}
            className={classes.pageGrid}
          >
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant='h1' className={classes.h1}>
                Une question, un renseignement&nbsp;?<br />
                Contactez-nous
              </Typography>
            </Grid>
          </Grid>
          <Grid container
            direction='row'
            justify='space-between'
            alignItems='flex-start'
            alignContent='stretch'
            spacing={16}
            className={classes.pageGrid}
          >
            <Grid item xs={12} sm={12} md={3}>
              <Typography variant='subtitle1' className={classes.subtitle1}>
                Merci de remplir le formulaire ci-dessous, notre équipe vous répondra dans les plus brefs délais.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
              <ContactForm apiUrl={apiUrl} gReCPATCHAKey={gReCPATCHAKey} />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <CustomerServiceCard
                text='Vous pouvez également contacter notre service client&nbsp;:'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}

export default withStyles(styles)(contact)

export const query = graphql`
  query {
    site {
      siteMetadata {
        apiUrl
        gReCPATCHAKey
      }
    }
  }
`
