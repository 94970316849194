import React from 'react'

import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'

import * as Yup from 'yup'

import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

// import classNames from 'classnames'
import { siteTheme } from '../layout/theme'

import * as API from '../../api/API'
import { createContactRequest } from '../../state/actions'
import { validateSchema } from './formUtils'

import FTextField from '../ui/FTextField'
import FButton from '../ui/FButton'
import FCaptcha from '../ui/FCaptcha'

import IcDone from '@material-ui/icons/Done'

const styles = theme => ({
  form: {
    //
  },
  formFields: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formButtons: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    margin: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`
  //   width: `100%`
  },
  textAreaField: {
    margin: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`
    // width: '100%'
  },
  captchaField: {
    margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
    width: '100%',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    boxShadow: siteTheme.boxShadow4
  },
  successContainer: {
    padding: `${theme.spacing.unit * 3}px`,
    // padding: '24px 16px',
    textAlign: 'center'
  },
  icDone: {
    fontSize: '64px'
  },
  successTitle: {
    margin: '24px 0px'
  },
  successMessage: {
    margin: '24px 0px'
  },
  buttonContainer: {
    margin: '24px 0px',
    textAlign: 'center'
  },
  successButton: {
    width: '100%',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    boxShadow: siteTheme.boxShadow4
  },
  errorContainer: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.2),
    border: `solid 1px ${theme.palette.error.dark}`,
    borderRadius: theme.spacing.unit,
    textAlign: 'center'
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Votre nom est requis'),
  email: Yup.string()
    .required('Votre adress email est requise')
    .email('Adresse email invalide'),
  phoneNumber: Yup.string()
    .required('Votre numéro de téléphone est requis'),
  title: Yup.string()
    .required('Veuillez saisir un objet'),
  message: Yup.string()
    .required('Veuillez saisir un message'),
  captchaToken: Yup.string()
    .required()
})

const initialValues = {
  name: '',
  company: '',
  email: '',
  phoneNumber: '',
  title: '',
  message: '',
  captchaToken: ''
}

class ContactForm extends React.Component {
  constructor (props) {
    super(props)

    // Bind class methods
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (values, dispatch) {
    return API.postContactRequest(this.props.apiUrl, {
      name: values.name,
      company: values.company,
      email: values.email,
      phoneNumber: values.phoneNumber,
      title: values.title,
      message: values.message,
      captchaToken: values.captchaToken
    })
      .then(
        (contactRequest) => {
          // Call callback function if present
          // (callback && callback(contactRequest))
          dispatch(createContactRequest(contactRequest))
        },
        (err) => {
          console.log(err)
          throw new SubmissionError({
            _error: err.statusText
          })
        })
  }

  /*
  props:
    anyTouched : boolean
    array : Object
    asyncValidate : Function
    asyncValidating : String | boolean
    autofill(field:String, value:any) : Function
    blur(field:String, value:any) : Function
    change(field:String, value:any) : Function
    clearAsyncError(field:String) : Function
    destroy() : Function
    dirty : boolean
    error : any
    form : String
    handleSubmit(eventOrSubmit) : Function
    initialize(data:Object) : Function
    initialized : boolean
    initialValues : Object
    invalid : boolean
    pristine: boolean
    reset() : Function
    resetSection(...sections:String) : Function
    submitFailed : boolean
    submitSucceeded : boolean
    submitting : boolean
    touch(...field:string) : Function
    untouch(...field:string) : Function
    valid : boolean
    warning : any
  */
  render () {
    const {
      classes,
      gReCPATCHAKey,
      error,
      handleSubmit,
      reset,
      submitFailed,
      submitSucceeded,
      submitting,
      valid,
      contactRequest
    } = this.props

    if (submitSucceeded) {
      // Show success message only
      return (
        <Paper className={classes.successContainer}>
          <IcDone className={classes.icDone} color='primary' />
          <Typography variant='h2' className={classes.successTitle}>
            Votre demande a été transmise avec succès.
          </Typography>
          <Typography variant='body2' className={classes.successMessage}>
            Vous recevrez une confirmation par email à l'adresse&nbsp;:<br />
            <strong>{contactRequest && contactRequest.email}</strong>
          </Typography>
          <div className={classes.buttonContainer}>
            <FButton type='button'
              onClick={reset}
              label='Nouvelle demande'
              // color='primary'
              // variant='contained'
              className={classes.successButton}
            />
          </div>
        </Paper>
      )
    }

    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)} className={classes.container}>
          { submitFailed &&
            <div className={classes.errorContainer}>
              <Typography>
                Une erreur est survenue lors de l'envoi de votre demande.
              </Typography>
              <Typography>
                {error}
              </Typography>
            </div>
          }
          <div className={classes.formFields}>
            <Field component={FTextField}
              id='contact_name'
              name='name'
              autoComplete='name'
              label='Nom'
              placeholder='Entrez votre nom'
              required
              className={classes.textField}
            />
            <Field component={FTextField}
              id='contact_company'
              name='company'
              autoComplete='organization'
              label='Entreprise'
              placeholder='Entrez le nom de votre entreprise (optionnel)'
              className={classes.textField}
            />
            <Field component={FTextField}
              id='contact_email'
              name='email'
              autoComplete='email'
              label='Email'
              placeholder='Entrez votre adresse email'
              required
              className={classes.textField}
            />
            <Field component={FTextField}
              id='contact_phoneNumber'
              name='phoneNumber'
              autoComplete='tel'
              label='Téléphone'
              placeholder='Entrez votre numéro de téléphone'
              required
              className={classes.textField}
            />
            <Field component={FTextField}
              id='contact_title'
              name='title'
              label='Objet'
              placeholder='Objet de la demande'
              required
              className={classes.textField} />
            <Field component={FTextField}
              id='contact_message'
              name='message'
              label='Message'
              placeholder='Votre message'
              required
              multiline
              rows='8'
              className={classes.textAreaField}
            />
            <Field component={FCaptcha}
              name='captchaToken'
              gReCPATCHAKey={gReCPATCHAKey}
              className={classes.captchaField}
            />
          </div>
          <div className={classes.formButtons}>
            <FButton type='submit'
              label='Envoyer'
              // disabledLabel='Corriger'
              submittingLabel='Veuillez patienter...'
              valid={valid}
              submitting={submitting}
              variant='contained'
              color='primary'
              className={classes.button}
            />
            {/* <FButton type='button'
              onClick={reset}
              label='Effacer'
              submitting={submitting}
              variant='outlined'
              color='primary'
              className={classes.button}
            /> */}
          </div>
        </form>
      </React.Fragment>
    )
  }
}

function mapStateToProps (state) {
  // Extract latest contactRequest
  const contactRequest = (state.contact && state.contact.contactRequest)
  // const {
  //   contact: {
  //     contactRequest
  //   }
  // } = state
  return {
    contactRequest: contactRequest
  }
}

// Selector decorator
// const contactFormSelector = formValueSelector('contact')
// ContactForm = connect(state => ({
//   formValues: contactFormSelector(state, 'name', 'company', 'email', 'phoneNumber', 'title', 'message', 'captchaToken')
// }))(ContactForm)

export default reduxForm({
  form: 'contact',
  initialValues: initialValues,
  validate: validateSchema(validationSchema),
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(
  connect(mapStateToProps, null)(
    withStyles(styles)(ContactForm)
  )
)
